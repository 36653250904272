var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title-class":"h3 text-white","centered":"","size":"xmd","title":'Add Message ' + _vm.getTitle,"hide-footer":"","modal-class":"modal-primary"},on:{"hidden":function($event){return _vm.hideModal()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',[_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.addMessage.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"placeholder":"Enter something..."},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"document-select","label":"Document Type","label-for":"service-select","label-class":"margin-bottom-10px"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors[0] ? 'border-danger' : null,attrs:{"options":_vm.documents,"label":"name","reduce":function (x) { return x.id; },"searchable":true,"placeholder":"Select document","id":"service-select","clearable":false,"selectable":function (option) { return !option.disabled; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(name),expression:"name",modifiers:{"hover":true,"bottom":true}}]},[_vm._v(_vm._s(name))])]}}],null,true),model:{value:(_vm.form.typeDocument),callback:function ($$v) {_vm.$set(_vm.form, "typeDocument", $$v)},expression:"form.typeDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Message in Spanish"}},[(_vm.type == 1)?_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1",attrs:{"prepend":"Subject","label-cols-md":"3"}},[_c('b-form-input',{staticClass:"mb-0",class:errors[0] ? 'border-danger' : null,model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"rules":'required|containsAtRange:' + _vm.containsAtRange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.type == 1)?_c('quill-editor',{staticClass:"quill-br",class:errors[0] && !_vm.isDisable ? 'border-danger' : '',staticStyle:{"width":"100%"},attrs:{"options":_vm.editorOption,"disabled":_vm.isDisable},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}}):_c('b-form-textarea',{class:errors[0] && !_vm.isDisable ? 'border-danger' : '',staticStyle:{"height":"240px"},attrs:{"disabled":_vm.isDisable,"id":"textarea-1","placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Message in English"}},[(_vm.type == 1)?_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1",attrs:{"prepend":"Subject","label-cols-md":"3"}},[_c('b-form-input',{staticClass:"mb-0",class:errors[0] ? 'border-danger' : null,model:{value:(_vm.form.en_subject),callback:function ($$v) {_vm.$set(_vm.form, "en_subject", $$v)},expression:"form.en_subject"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"rules":'required|containsAtRange:' + _vm.containsAtRange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.type == 1)?_c('quill-editor',{staticClass:"quill-br",class:errors[0] && !_vm.isDisable ? 'border-danger' : '',staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisable,"options":_vm.editorOption},model:{value:(_vm.form.en_body),callback:function ($$v) {_vm.$set(_vm.form, "en_body", $$v)},expression:"form.en_body"}}):_c('b-form-textarea',{class:errors[0] && !_vm.isDisable ? 'border-danger' : '',staticStyle:{"height":"240px"},attrs:{"disabled":_vm.isDisable,"id":"textarea-1","placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.form.en_body),callback:function ($$v) {_vm.$set(_vm.form, "en_body", $$v)},expression:"form.en_body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',{staticClass:"text-muted",staticStyle:{"font-weight":"bold"}},[_vm._v("The message must have the following vars. ")])])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-input-group',{attrs:{"size":"lg","prepend":"@1"}},[_c('b-form-input',{attrs:{"id":"input-1","trim":"","disabled":"","placeholder":"Full Name"}})],1)],1),(_vm.type == 1)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-input-group',{attrs:{"size":"lg","prepend":"@2"}},[_c('b-form-input',{attrs:{"id":"input-1","trim":"","disabled":"","placeholder":"Departament"}})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid},on:{"click":_vm.addMessage}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" SAVE")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }