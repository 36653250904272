import { amgApi } from "@/service/axios";

class MessageConfigService {
  

  async getMessagesSms(params) {
    try {
      const data = await amgApi.post("/debt-solution/get-message-config-ci",params);
      return data;
    } catch (error) {
      return error;
    }
  }
 
  async createMessage(params) {
    try {
      const data = await amgApi.post("/debt-solution/create-message-ci",params);
      return data;
    } catch (error) {
      return error;
    }
  }

  async updateMessage(params) {
    try {
      const data = await amgApi.post("/debt-solution/update-message-ci",params);
      return data;
    } catch (error) {
      return error;
    }
  }

  async deleteMessage(params) {
    try {
      const data = await amgApi.post("/debt-solution/delete-message-ci",params);
      return data;
    } catch (error) {
      return error;
    }
  }
}

export default new MessageConfigService();
