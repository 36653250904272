<template>
  <b-modal
    v-model="show"
    title-class="h3 text-white"
    centered
    size="xmd"
    :title="'Add Message ' + getTitle"
    hide-footer
    modal-class="modal-primary"
    @hidden="hideModal()"
  >
    <validation-observer #default="{ invalid }">
      <b-row>
        <b-col>
          <b-form class="auth-login-form" @submit.prevent="addMessage">
            <b-row>
              <b-col>
                <b-form-group label="Title">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      v-model="form.title"
                      placeholder="Enter something..."
                      :class="errors[0] ? 'border-danger' : ''"
                    ></b-form-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="document-select"
                  label="Document Type"
                  label-for="service-select"
                  label-class="margin-bottom-10px"
                >
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      :options="documents"
                      label="name"
                      v-model="form.typeDocument"
                      :reduce="(x) => x.id"
                      :searchable="true"
                      placeholder="Select document"
                      id="service-select"
                      :clearable="false"
                      :selectable="(option) => !option.disabled"
                      :class="errors[0] ? 'border-danger' : null"
                    >
                      <template #selected-option="{ name }">
                        <span v-b-tooltip.hover.bottom="name">{{ name }}</span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group label="Message in Spanish" class="col-6">
                <div v-if="type == 1">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-input-group
                      prepend="Subject"
                      label-cols-md="3"
                      class="mb-1"
                    >
                      <b-form-input
                        v-model="form.subject"
                        :class="errors[0] ? 'border-danger' : null"
                        class="mb-0"
                      />
                    </b-input-group>
                    <small v-if="errors[0]" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :rules="'required|containsAtRange:' + containsAtRange"
                >
                  <quill-editor
                    v-if="type == 1"
                    style="width: 100%"
                    v-model="form.body"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    class="quill-br"
                    :options="editorOption"
                    :disabled="isDisable"
                  />
                  <b-form-textarea
                    v-else
                    v-model="form.body"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    style="height: 240px"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Message in English" class="col-6">
                <div v-if="type == 1">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-input-group
                      prepend="Subject"
                      label-cols-md="3"
                      class="mb-1"
                    >
                      <b-form-input
                        v-model="form.en_subject"
                        :class="errors[0] ? 'border-danger' : null"
                        class="mb-0"
                      />
                    </b-input-group>
                    <small v-if="errors[0]" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :rules="'required|containsAtRange:' + containsAtRange"
                >
                  <quill-editor
                    v-if="type == 1"
                    style="width: 100%"
                    v-model="form.en_body"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    :disabled="isDisable"
                    class="quill-br"
                    :options="editorOption"
                  />
                  <b-form-textarea
                    v-else
                    v-model="form.en_body"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    style="height: 240px"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <span style="font-weight: bold" class="text-muted"
            >The message must have the following vars.
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col lg="6">
          <b-input-group size="lg" prepend="@1">
            <b-form-input
              id="input-1"
              trim
              disabled
              placeholder="Full Name"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col v-if="type == 1" lg="6">
          <b-input-group size="lg" prepend="@2">
            <b-form-input
              id="input-1"
              trim
              disabled
              placeholder="Departament"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-button
          type="submit"
          variant="primary"
          class="mx-1"
          block
          :disabled="invalid"
          @click="addMessage"
        >
          <feather-icon icon="SaveIcon"> </feather-icon>
          SAVE</b-button
        >
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import MessageConfigService from "@/views/debt-solution/views/settings/views/message-cost-config/service/MessageConfig.service.js";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import { mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "ModalAddMotive",
  components: {
    quillEditor,
  },
  props: {
    type: Number,
    containsAtRange: Number,
  },
  data() {
    return {
      show: true,
      form: {
        title: null,
        subject: null,
        en_subject: null,
        body: null,
        en_body: null,
      },
      isDisable: false,
      editorOption: {
        modules: { toolbar: true },
      },
      documents: [],
    };
  },
  mounted() {
    this.getCiDocumentsByServiceType();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTitle() {
      return this.type == 1 ? "Email/Letter" : "Sms";
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async addMessage() {
      try {
        this.addPreloader();
        const params = {
          subject: this.form.subject,
          en_subject: this.form.en_subject,
          title: this.form.title,
          body: this.form.body,
          en_body: this.form.en_body,
          type: this.type,
          module_id: this.currentUser.modul_id,
          user_id: this.currentUser.user_id,
          typeDocument: this.form.typeDocument,
        };
        const response = await MessageConfigService.createMessage(params);
        if (response.status === 200) {
          this.showSuccessSwal();
          this.$emit("hide", true);
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async getCiDocumentsByServiceType() {
      try {
        let params = {
          service_type_id: 1,
        };

        const { data } = await CourtInfoService.getCiDocumentsByServiceType(
          params
        );
        this.documents = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocumentsByServiceType!"
        );
      }
    },
  },
};
</script>

<style>
.margin-bottom-10px {
  margin-bottom: 10px !important;
}
</style>