<template>
  <div class="message-config-tab">
    <validation-observer #default="{ invalid }">
      <form>
        <b-card>
          <b-row>
            <b-col class="text-center d-flex justify-content-between" cols="12">
              <div>
                <h2 class="mb-0">{{ getTitle }}</h2>
              </div>
              <div>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="showAddModal"
                >
                  <feather-icon icon="PlusIcon"> </feather-icon>
                  ADD
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="6" offset="6">
              <b-form-group label="Template">
                <v-select
                  v-model="smsSelected"
                  :options="options"
                  label="title"
                  :clearable="false"
                >
                  <template #selected-option="{ title, document_name }">
                    <span v-b-tooltip.hover.bottom="title"
                      >{{ title }}
                      <span
                        v-if="document_name != null"
                        class="text-dark-light"
                      >
                        - {{ document_name }}
                      </span></span
                    >
                  </template>
                  <template #option="{ title, document_name }">
                    <span v-b-tooltip.hover.bottom="title"
                      >{{ title }}
                      <span
                        v-if="document_name != null"
                        class="text-dark-light"
                      >
                        - {{ document_name }}
                      </span></span
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Document">
                <v-select
                  v-model="fullSmsSelected.service_document_id"
                  :options="documents"
                  label="name"
                  :reduce="(document) => document.id"
                  :disabled="isDisable"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="fullSmsSelected.title"
                  :disabled="isDisable"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                :rules="'required|containsAtRange:' + containsAtRange"
              >
                <b-form-group label="Message in Spanish" label-for="textarea-1">
                  <div v-if="type == 1">
                    <validation-provider v-slot="{ errors }" rules="required">
                      <b-input-group
                        prepend="Subject"
                        label-cols-md="3"
                        class="mb-1"
                      >
                        <b-form-input
                          v-model="fullSmsSelected.subject"
                          :class="
                            errors[0] && !isDisable ? 'border-danger' : null
                          "
                          :disabled="isDisable"
                          class="mb-0"
                        />
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">{{
                        errors[0] && !isDisable ? errors[0] : ""
                      }}</small>
                    </validation-provider>
                  </div>
                  <quill-editor
                    v-if="type == 1"
                    style="width: 100%"
                    v-model="fullSmsSelected.body"
                    :class="{
                      'quill-editor-disabled': isDisable,
                      'quill-editor-not-disabled': !isDisable,
                      'border-danger': errors[0] && !isDisable,
                    }"
                    class="quill-br"
                    :options="editorOption"
                    :disabled="isDisable"
                  />
                  <b-form-textarea
                    v-else
                    v-model="fullSmsSelected.body"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    style="height: 240px"
                  ></b-form-textarea>
                </b-form-group>

                <small class="text-danger">{{
                  errors[0] && !isDisable ? errors[0] : ""
                }}</small>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider
                #default="{ errors }"
                :rules="'required|containsAtRange:' + containsAtRange"
              >
                <b-form-group label="Message in English" label-for="textarea-1">
                  <div v-if="type == 1">
                    <validation-provider v-slot="{ errors }" rules="required">
                      <b-input-group
                        prepend="Subject"
                        label-cols-md="3"
                        class="mb-1"
                      >
                        <b-form-input
                          v-model="fullSmsSelected.en_subject"
                          :class="
                            errors[0] && !isDisable ? 'border-danger' : null
                          "
                          :disabled="isDisable"
                          class="mb-0"
                        />
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">{{
                        errors[0] && !isDisable ? errors[0] : ""
                      }}</small>
                    </validation-provider>
                  </div>
                  <quill-editor
                    v-if="type == 1"
                    style="width: 100%"
                    v-model="fullSmsSelected.en_body"
                    :class="{
                      'quill-editor-disabled': isDisable,
                      'quill-editor-not-disabled': !isDisable,
                      'border-danger': errors[0] && !isDisable,
                    }"
                    :disabled="isDisable"
                    class="quill-br"
                    :options="editorOption"
                  />
                  <b-form-textarea
                    v-else
                    v-model="fullSmsSelected.en_body"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] && !isDisable ? 'border-danger' : ''"
                    style="height: 240px"
                  ></b-form-textarea>
                </b-form-group>

                <small class="text-danger">{{
                  errors[0] && !isDisable ? errors[0] : ""
                }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-if="fullSmsSelected.id">
            <b-col cols="12">
              <b-row style="margin-bottom: 10px">
                <b-col>
                  <span style="font-weight: bold">VARS</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6 mb-1">
                  <b-input-group size="lg" prepend="@1">
                    <b-form-input
                      id="input-1"
                      trim
                      disabled
                      placeholder="Full Name"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col lg="6 mb-1" v-if="type == 1">
                  <b-input-group size="lg" prepend="@2">
                    <b-form-input
                      id="input-1"
                      trim
                      disabled
                      placeholder="Departament"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              v-if="isDisable && fullSmsSelected.id"
              lg="12"
              class="text-center"
            >
              <b-button variant="warning" @click="isDisable = false">
                <feather-icon icon="Edit3Icon"> </feather-icon>
                UPDATE</b-button
              >
            </b-col>

            <b-col v-if="!isDisable" lg="12" class="text-center">
              <b-button
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="updateMessage"
              >
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >

              <b-button variant="danger" class="mr-1" @click="deleteMessage">
                <feather-icon icon="TrashIcon"> </feather-icon>
                REMOVE</b-button
              >

              <b-button @click="returnMessage">
                <feather-icon icon="XIcon"> </feather-icon>
                CANCEL</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </form>
    </validation-observer>

    <new-message
      v-if="showModal"
      :type="type"
      :containsAtRange="containsAtRange"
      @hide="hide"
    />
  </div>
</template>

<script>
import MessageConfigService from "@/views/debt-solution/views/settings/views/message-cost-config/service/MessageConfig.service.js";
import NewMessage from "@/views/debt-solution/views/settings/views/message-cost-config/modal/NewMessage.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";

import { mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "MessageConfigTab",
  components: {
    NewMessage,
    quillEditor,
  },
  props: {
    template: {
      type: Object,
      required: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      options: [],
      documents: [],
      smsSelected: {
        title: "",
        subject: "",
      },
      fullSmsSelected: {},
      isDisable: true,
      collapseActive1: true,
      collapseActive2: false,
      messageToSearch: "",
      editorOption: {
        modules: { toolbar: true },
      },
    };
  },
  watch: {
    smsSelected: {
      handler(newVal) {
        this.fullSmsSelected = { ...newVal };
      },
      deep: true,
    },
  },
  async mounted() {
    // next stick
    this.$nextTick(() => {
      this.getCiDocumentsByServiceType();
      this.getMessagesSms();
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTitle() {
      return this.type == 1 ? "EMAIL/LETTER" : "SMS";
    },
    type() {
      return this.$route.meta.type ?? this.template.type;
    },
    containsAtRange() {
      return this.$route.meta.containsAtRange;
    },
  },
  methods: {
    showAddModal() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.getMessagesSms();
    },
    async getMessagesSms() {
      this.addPreloader();
      try {
        const params = {
          type: this.type,
        };
        const { data } = await MessageConfigService.getMessagesSms(params);
        this.options = data;

        if (data.length > 0) {
          if (this.template) {
            this.smsSelected = data.filter(
              (item) => item.id == this.template.id
            )[0];
          } else {
            const data_message = data.filter((item) => item.default == 1)[0];
            this.smsSelected = data_message == null ? data[0] : data_message;
          }

          // filter templates by document type selected if comes from modal
          if (this.isModal) {
            let documentId = this.template.templateSelected.document_id;
            this.options = this.options.filter(
              (item) => item.document_id == documentId
            );
          }
        } else {
          this.smsSelected = {
            title: "",
          };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateMessage() {
      const params = {
        id: this.fullSmsSelected.id,
        body: this.fullSmsSelected.body,
        en_body: this.fullSmsSelected.en_body,
        subject: this.fullSmsSelected.subject,
        en_subject: this.fullSmsSelected.en_subject,
        user_id: this.currentUser.user_id,
        service_document_id: this.fullSmsSelected.service_document_id,
        title: this.fullSmsSelected.title,
      };
      const val = this.smsSelected;

      const swalConfirm = await this.showConfirmSwal();
      if (swalConfirm.isConfirmed) {
        const { data, status } = await MessageConfigService.updateMessage(
          params
        );
        if (status === 200) {
          this.showSuccessSwal();
          this.isDisable = true;
          await this.getMessagesSms();
          this.smsSelected = this.options.filter((sms) => sms.id == val.id)[0];
        }
      }
    },
    async deleteMessage() {
      const params = {
        id: this.fullSmsSelected.id,
      };
      const swalConfirm = await this.showConfirmSwal();
      if (swalConfirm.isConfirmed) {
        const { data, status } = await MessageConfigService.deleteMessage(
          params
        );
        if (status === 200) {
          this.showSuccessSwal();
          this.isDisable = true;
          await this.getMessagesSms();
        }
      }
    },
    async returnMessage() {
      const val = this.smsSelected;
      await this.getMessagesSms();
      this.isDisable = true;
      this.smsSelected = this.options.filter((sms) => sms.id == val.id)[0];
    },

    async getCiDocumentsByServiceType() {
      try {
        let params = {
          service_type_id: 1,
        };

        const { data } = await CourtInfoService.getCiDocumentsByServiceType(
          params
        );
        this.documents = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocumentsByServiceType!"
        );
      }
    },
  },
};
</script>

<style lang="scss" >
.dark-layout .text-dark-light {
  color: #00ffff;
  font-weight: 500;
}

.text-dark-light {
  color: #ff5733;
  font-weight: 500;
}

.message-config-tab {
  .yellow-fill {
    fill: #efb911;
  }

  .quill-editor-disabled {
    background: #efefef !important;
  }
}

.dark-layout {
  .message-config-tab {
    .quill-editor-disabled {
      background: #17171a !important;
      color: #636365;
    }
    .quill-editor-not-disabled {
      .ql-toolbar,
      .ql-container {
        border-color: #747476 !important;
        color: #afafb0 !important;
      }
    }
  }
}
</style>
